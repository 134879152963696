var render = function render(){var _vm=this,_c=_vm._self._c;return (
    typeof _vm.settings !== 'undefined' &&
    typeof _vm.settings.commonSettings !== 'undefined' &&
    (('homepageHintTitle' in _vm.settings.commonSettings &&
      _vm.settings.commonSettings.homepageHintTitle !== '') ||
      ('homepageHint' in _vm.settings.commonSettings &&
        _vm.settings.commonSettings.homepageHint !== ''))
  )?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-alert',{attrs:{"prominent":"","type":"info"}},[_c('h3',[_vm._v(_vm._s(_vm.settings.commonSettings.homepageHintTitle))]),_c('span',{staticStyle:{"white-space":"pre-line"}},[_vm._v(_vm._s(_vm.settings.commonSettings.homepageHint))])])],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }