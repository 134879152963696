export const GET_JUMPDAYS = "getJumpdays";
export const ADD_JUMPDAY = "addJumpday";
export const UPDATE_JUMPDAY = "updateJumpday";
export const DELETE_JUMPDAY = "deleteJumpday";
export const GET_APPOINTMENTS = "getAppointments";
export const UPDATE_APPOINTMENT = "updateAppointment";
export const DELETE_APPOINTMENT = "deleteAppointment";
export const UPDATE_APPOINTMENT_STATE = "updateAppointmentState";
export const GET_GROUP_SLOTS = "getGroupSlots";
export const GET_TANDEMMASTERS = "getTandemmasters";
export const ADD_TANDEMMASTER = "addTandemmaster";
export const UPDATE_TANDEMMASTER = "updateTandemmaster";
export const DELETE_TANDEMMASTER = "deleteTandemmaster";
export const GET_VIDEOFLYERS = "getVideoflyers";
export const ADD_VIDEOFLYER = "addVideoflyer";
export const UPDATE_VIDEOFLYER = "updateVideoflyer";
export const DELETE_VIDEOFLYER = "deleteVideoflyer";
export const SET_LOCALE = "setLocale";
export const UPDATE_LOCAL_SETTINGS = "updateLocalSettingsAction";
export const GET_COMMON_SETTINGS = "getCommonSettingsAction";
export const SET_SETTINGS = "setSettings";
