var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{style:({
    background:
      'center top no-repeat fixed url(' +
      require('../assets/tandem-background.jpeg') +
      ')',
    backgroundSize: 'cover',
    height: '100%',
  }),attrs:{"fluid":""}},[_c('HomepageHint'),_c('BookAppointment')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }